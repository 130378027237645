<template>
    <div>
        
<v-layout wrap justify-center  pb-12>
    <v-flex xs12 sm10 md10 lg10 text-center pb-12 data-aos="zoom-in-up" data-aos-ease="ease"
    data-aos-duration="1500" data-aos-delay="500">
        <v-layout wrap justify-space-between>
        <v-flex xs12 sm12 md7 lg7 ><v-layout wrap>
            <v-flex xs12  sm12 md12 lg12 text-center  pb-12>
                <v-card style="color: #1B6DC1;" class="pa-6" v-bind:class="{ expanded: isExpanded1 }">
                    <v-card-title>
                    <span style="font-family: OutfitSemiBold; font-weight: bold; color: #1B6DC1" :style="{
                        'line-height': $vuetify.breakpoint.name == 'xs' ? '18px' : $vuetify.breakpoint.name == 'xl' ? '37px' : '25px',
                        'font-size': $vuetify.breakpoint.name == 'xs' ? '16px' : $vuetify.breakpoint.name == 'xl' ? '37px' : '18px',
                    }">
                        Course Duration
                    </span>
                    </v-card-title>

                    <v-card-text style="text-align: justify;">
                    <p style="font-family: OutfitBold; color: #1A2421">193 Days classes- 5 Days in a week-Course runs Monday to Friday (9.30AM to 3PM) on normal working days.</p>

                    <p style="font-family: OutfitBold; color: #1A2421">Course Objective A - Basic Level</p>
                    <p style="font-family: JostRegular; color: #1A2421">
                        <span style="font-family: OutfitBold; color: black">Step 1</span><br>
                        Helps the candidate to build confidence in navigating through commonplace situations, such as shopping at the supermarket, asking for directions, ordering food and drink at restaurants, and making arrangements to meet people. You can also develop some useful everyday vocabulary
                    </p>
                    <p style="font-family: JostRegular; color: #1A2421">
                        <span style="font-family: OutfitBold; color: #1A2421">Step 2</span><br>
                        Candidates gained a firm grasp on the very basics and now they would move to the next level. 
                        like to take it to the next level. In the second step the learner will learn how to talk more in depth 
                        about their thoughts and opinions, as well as how to state problems. Learner will practice arranging appointments with friends, 
                        or even with the doctor. 
                        Also become capable to discuss holiday celebrations and more about the city live in.
                    </p>

                    <!-- Extra content to display when expanded -->
                    <div v-if="isExpanded1">
                        <!-- Additional content goes here -->
                        
                        <p style="font-family: JostRegular; color: #1A2421">
                        <span style="font-family: OutfitBold; color: #1A2421">Step 3</span><br>
                        In the third step the learner will continue to practice making appointments and requests. 
                        In this way, the learner  will be able to broaden your knowledge regarding various everyday situations.
                        Main themes of this course will expand on topics relating to leisure, holidays, and city life.
                    </p>

                    <p style="font-family: JostRegular; color: #1A2421">
                        <span style="font-family: OutfitBold; color: #1A2421">Step 4</span><br>
                        At this level the learner will continue practicing expressing their opinions and wishes using persuasive speech. 
                        Also become capable to tackle everyday life issues and experiences in order to expand their vocabulary. 
                    </p>

                    <p style="font-family: OutfitBold; color: #1A2421">Course Objective B - Advanced Level</p>
                    <p style="font-family: JostRegular; color: #1A2421">
                        <span style="font-family: OutfitBold; color: black">Step 1</span><br>
                        In this level the learner   will continue to expand their vocabulary 
                        regularly and practice writing emails and formal letters. Also will learn how to write 
                        complaint letters, work related letters and motivation letters.
                    </p>
                    <p style="font-family: JostRegular; color: #1A2421">
                        <span style="font-family: OutfitBold; color: #1A2421">Step 2</span><br>
                        In this step the learner will continue to expand their  vocabulary - even on topics that aren’t 
                        necessarily used in daily life. They will talk about arts, history, and cultural events. In addition, 
                        also will start making things even more interesting by
                        introducing more complex grammar structures as well as presentations.
                    </p>
                    <p style="font-family: JostRegular; color: #1A2421">
                        <span style="font-family: OutfitBold; color: #1A2421">Step 3</span><br>
                        The learner  will talk about arts, history, and cultural events. In addition, will start making things even more 
                        interesting by introducing more complex grammar structures as well as presentations.
                    </p>
                    <p style="font-family: JostRegular; color: #1A2421">
                        <span style="font-family: OutfitBold; color: #1A2421">Step 4</span><br>
                        At this level the learner will learn how to create meaningful coherent texts and longer written statements 
                        such as university/work application motivation letters. 
                    </p>
                    </div>
                    </v-card-text>
            
            <!-- See More button -->
            <v-flex xs12 ml-1 class="text-left">
            <v-btn text color="#0064CB" small @click="toggleExpansion1">
                <v-icon color="#0064CB" style="border: 1px solid currentColor; border-radius: 50%; padding: 0px; font-size: 12px;" small>
                mdi-arrow-right
                </v-icon>
                &nbsp;<span style="font-family: JostBold;">{{ isExpanded1 ? 'See Less' : 'See More' }}</span>
            </v-btn>
            </v-flex>
                </v-card>
            </v-flex>   
    </v-layout>

    <v-layout wrap>
        <v-flex xs12 sm12 md12 lg12 pb-12>
            <v-card style="color: #1B6DC1;" class="pa-6">
                <v-card-title>
                    <span style="font-family: OutfitSemiBold; font-weight: bold; color: #1B6DC1" :style="{
                        'line-height': $vuetify.breakpoint.name == 'xs' ? '18px' : $vuetify.breakpoint.name == 'xl' ? '37px' : '25px',
                        'font-size': $vuetify.breakpoint.name == 'xs' ? '16px' : $vuetify.breakpoint.name == 'xl' ? '37px' : '18px',
                    }">
                        Admission Requirements
                    </span>
                </v-card-title>

                <v-card-text style="text-align: justify;">
                    
                    <p style="font-family: JostRegular; color: #1A2421">
                    
                        Successfully complete Year 12(Plus 2) in Science, Commerce, Humanities etc.
                        <br> <br>Pass Plus 2 or Plus 1 with 60% marks. 
                        80% marks in English exam. If the Learner doesn’t have require 80% score, they will be required to sit for an English test
                        to prove their language skill. If you haven’t received the mark list for Plus 2 for admission purpose the plus 1 scores will be considered.
                    </p>
                
                    
                </v-card-text>
            
            </v-card>
        </v-flex>


        <v-flex xs12 sm12 md12 lg12 pb-12>
            <v-card style="color: #1B6DC1;" class="pa-6">
                <v-card-title>
                    <span style="font-family: OutfitSemiBold; font-weight: bold; color: #1B6DC1" :style="{
                        'line-height': $vuetify.breakpoint.name == 'xs' ? '18px' : $vuetify.breakpoint.name == 'xl' ? '37px' : '25px',
                        'font-size': $vuetify.breakpoint.name == 'xs' ? '16px' : $vuetify.breakpoint.name == 'xl' ? '37px' : '18px',
                    }">
                        Fee Structure
                    </span>
                </v-card-title>

                <v-card-text style="text-align: justify;">
            <div style="font-family: OutfitBold; color: #1A2421">Total Fees – INR 77200.The candidates can pay the fees is in two instalments as below. </div>
            <ul style="list-style-type: none; padding-left: 0;">
                <li style="font-family: JostRegular; color: #1A2421; padding-left: 15px;">- First Instalment at the time of admission INR 36000(A Level)</li>
                <li style="font-family: JostRegular; color: #1A2421; padding-left: 15px;">- Final Payment INR 41200(B level)</li>
            </ul>
        </v-card-text>
        </v-card>
        </v-flex>

        <v-flex xs12 sm12 md12 lg12 pb-12>
            <v-card style="color: #1B6DC1;" class="pa-6">
                <v-card-title>
                    <span style="font-family: OutfitSemiBold; font-weight: bold; color: #1B6DC1" :style="{
                        'line-height': $vuetify.breakpoint.name == 'xs' ? '18px' : $vuetify.breakpoint.name == 'xl' ? '37px' : '25px',
                        'font-size': $vuetify.breakpoint.name == 'xs' ? '16px' : $vuetify.breakpoint.name == 'xl' ? '37px' : '18px',
                    }">
                    Exam Fees
                    </span>
                </v-card-title>

                <v-card-text style="text-align: justify;">
        
            <p style="font-family: JostRegular; color: #1A2421">
                    
                Average exam fees for all the four modules is around INR 35000.
            
            </p>
        </v-card-text>
        </v-card>
        </v-flex>


        <v-flex xs12 sm12 md12 lg12 pb-12>
            <v-card style="color: #1B6DC1;" class="pa-6" v-bind:class="{ expanded: isExpanded2 }">
                <v-card-title>
                    <span style="font-family: OutfitSemiBold; font-weight: bold; color: #1B6DC1" :style="{
                        'line-height': $vuetify.breakpoint.name == 'xs' ? '18px' : $vuetify.breakpoint.name == 'xl' ? '37px' : '25px',
                        'font-size': $vuetify.breakpoint.name == 'xs' ? '16px' : $vuetify.breakpoint.name == 'xl' ? '37px' : '18px',
                    }">
                        Language Proficiency Exams on Completion of A&B Level
                    </span>
                </v-card-title>

                <v-card-text style="text-align: justify;">
                    
                    <p style="font-family: JostRegular; color: #1A2421">
                        
                        Once the course (A&B level) is completed the next step is to appear for the language proficiency exam. 
                        In India the exam is available for  GOTHE,OSD,TELC or ECL language proficiency tests. 
                        At this stage most of the candidates in India is preferring GOTHE test and due to the large demand, 
                        there is a long waiting period to get in to this exam. At the same time OSD, TELC and ECL language proficiency 
                        certificates also equally eligible to to get German Visa. The minimum required score to pass the exam is 60%.   </p>
                    
                        <div v-if="isExpanded2">
                            <p style="font-family: JostRegular; color: #1A2421"> Just like any other language tests, all German language proficiency exam has 4 modules- Reading, Writing, 
                        Listening and Speaking. GOTHE is a 60 points exam and the candidate have to score 36 points (60%) for each module to pass the exam. 
                        However, to pass OSD,  TELC and ECL proficiency exams 60% score for the speaking test and 60% for the remaining three module combined.</p>
                        </div>
                        <v-flex ml-0>
                        <!-- Use <router-link> for navigation -->
                        <router-link to="" style="text-decoration: none;">
                            <!-- You can use any icon library for the arrow icon, here I'm using Material Design Icons -->
                            <v-btn text color="#0064CB" style="margin-left:-10px;" small @click="toggleExpansion2">
                                <v-icon color="#0064CB" style="border: 1px solid currentColor; border-radius: 50%; padding: 0px; font-size: 12px;" small>
                                    mdi-arrow-right
                                </v-icon>
                                &nbsp;<span style="font-family: JostBold;">{{ isExpanded2 ? 'See Less' : 'See More' }}</span>
                                <!-- Icon for the arrow, adjust the icon and style as needed -->
                            </v-btn>
                        </router-link>
                </v-flex> 
                </v-card-text>
         
            </v-card>
        </v-flex>

    <v-flex xs12 sm12 md12 lg12 pb-12>
        <v-card style="color: #1B6DC1;" class="pa-6" v-bind:class="{ expanded: isExpanded3 }">
            <v-card-title>
                <span style="font-family: OutfitSemiBold; font-weight: bold; color: #1B6DC1" :style="{
                    'line-height': $vuetify.breakpoint.name == 'xs' ? '18px' : $vuetify.breakpoint.name == 'xl' ? '37px' : '25px',
                    'font-size': $vuetify.breakpoint.name == 'xs' ? '16px' : $vuetify.breakpoint.name == 'xl' ? '37px' : '18px',
                }">
                    Study & Job Opportunities in Germany
                </span>
            </v-card-title>

            <v-card-text style="text-align: justify;">
                
                <p style="font-family: JostRegular; color: #1A2421">
                    
                    To study in Germany, you need to prove that you speak  German well enough to study in that language. 
                    This proof needs to be delivered by handing in an official language certificate (minimum B Level) unless 
                    you are a native speaker or have graduated from an English or German speaking program.
                
                </p>
                <div v-if="isExpanded3">
                    <p style="font-family: JostRegular; color: #1A2421">
                    
                        Benefit from a high-quality educational system to help you advance in your career, learn a new language 
                        by making new friends and socializing with other students, and gain an amazing life experience to remember 
                        for years to come by studying and living as a foreign student in Germany. There are hundreds of universities
                        in Germany that have free or very low-fee tuition programs available for international students. Whether you
                        want to study Engineering, Medicine, Architecture, or Business, Germany is the place to be. Combining high 
                        quality education with a unique cultural experience living in Germany is the main reason why young people 
                        from all around the world come to Germany.
                
                </p>
                    </div>
                    <v-flex xs12 >
                    <!-- Use <router-link> for navigation -->
                    <router-link to="" style="text-decoration: none;">
                        <!-- You can use any icon library for the arrow icon, here I'm using Material Design Icons -->
                        <v-btn text color="#0064CB" style="margin-left:-15px;" small @click="toggleExpansion3">
                            <v-icon color="#0064CB" style="border: 1px solid currentColor; border-radius: 50%; padding: 0px; font-size: 12px;" small>
                                mdi-arrow-right
                            </v-icon>
                            &nbsp;<span style="font-family: JostBold;">{{ isExpanded3 ? 'See Less' : 'See More' }}</span>
                            <!-- Icon for the arrow, adjust the icon and style as needed -->
                        </v-btn>
                    </router-link>
            </v-flex>
            </v-card-text>
            
        </v-card>
    </v-flex>


    <v-flex xs12 sm12 md12 lg12 pb-12>
    <v-card style="color: #1B6DC1;" class="pa-6" v-bind:class="{ expanded: isExpanded4 }">
        <v-card-title>
            <span style="font-family: OutfitSemiBold; font-weight: bold; color: #1B6DC1" :style="{
                'line-height': $vuetify.breakpoint.name == 'xs' ? '18px' : $vuetify.breakpoint.name == 'xl' ? '37px' : '25px',
                'font-size': $vuetify.breakpoint.name == 'xs' ? '16px' : $vuetify.breakpoint.name == 'xl' ? '37px' : '18px',
            }">
                10 Great Reasons To Study in Germany as a Foreign Student
            </span>
        </v-card-title>

        <v-card-text style="text-align: justify;">
    <p style="font-family: JostRegular; color: #1A2421">
        <ul style="list-style-type: number; padding-left: 10;">
        <!-- Use a computed property to filter listItems based on isExpanded -->
        <li v-for="(item, index) in filteredListItems" :key="index" style="font-family: JostRegular; color: #1A2421; padding-left: 15px;">{{ item }}</li>
        </ul>
    </p>

    <!-- See More button -->
    <v-flex xs12>
        <!-- Use <router-link> for navigation -->
        <router-link to="" style="text-decoration: none;">
        <!-- You can use any icon library for the arrow icon, here I'm using Material Design Icons -->
        <v-btn text color="#0064CB" style="margin-left:-15px;" small @click="toggleExpansion4">
            <v-icon color="#0064CB" style="border: 1px solid currentColor; border-radius: 50%; padding: 0px; font-size: 12px;" small>
            mdi-arrow-right
            </v-icon>
            &nbsp;<span style="font-family: JostBold;">{{ isExpanded4 ? 'See Less' : 'See More' }}</span>
            <!-- Icon for the arrow, adjust the icon and style as needed -->
        </v-btn>
        </router-link>
    </v-flex>
    </v-card-text>
        
    </v-card>
    </v-flex>
    </v-layout>
    </v-flex>

    <v-flex xs12 sm12 md5 lg5 pl-0 pl-xs-0 pl-sm-0 pl-md-5 pl-lg-10 pl-xl-10>
        <v-layout wrap justify-end>
            <v-flex xs12 sm12 md12 lg12>

              <v-card style="color: #1B6DC1;" class="pa-5">
                    <v-card-title >
                    <span  style="font-family: OutfitSemiBold;  font-weight: bold; color: #1B6DC1;">
                        Related Courses
                    </span>
                    </v-card-title>
                    <v-flex pt-3 v-for="(item, i) in items" :key="i">
                        <router-link style="text-decoration:none;" :to="item.route">
                            <v-card color="#E6EFF9" style="color: #1B6DC1;" class="pa-2">
                            <v-layout wrap justify-center>
                                <v-flex xs3>
                                <v-img :src="item.img" width="300px" height="100px"></v-img>
                                </v-flex>
                                <v-flex xs9 pt-5>
                                <v-card-text>
                                    <span style="font-family: OutfitBold; font-size: 18px; color: black">{{ item.title }}</span>
                                </v-card-text>
                                </v-flex>
                            </v-layout>
                            </v-card>
                        </router-link>
                        

                    </v-flex>

              </v-card>
    
            </v-flex>
        </v-layout>
    </v-flex>
        </v-layout> 

    
    </v-flex>
</v-layout>
    </div>
  </template>
  
  <script>
export default {
  data() {
    return {
      isExpanded1: false,
      isExpanded2: false,
      isExpanded3: false,
      isExpanded4: false,
      listItems: [
        "Free-tuition education in most universities (and very low fees in others).",
        "World class education by highly qualified staff.",
        "Hundreds of academic courses to choose from.",
        "Interesting country to explore with a profound history.",
        "Cultural diversity and students from all over the world.",
        "Practice speaking both English and German.",
        "Endless opportunities to work in Germany after you graduate.",
        "You can study every subject in English, no need to speak German",
        "The cost of living in Germany is affordable, averaging around 720-850€ including rent, food, transport, health insurance, telephone/internet, study materials and leisure activities.",
        "The chance to live and explore one of the world’s most developed countries and its unique culture, history and landscape.",
      ],
      // rating: 4,
      items: [
        {
          img: require("./../../assets/images/excel.png"),
          title: "Advance Excel ",
          route:"/othercoursesingleview"
          //   review: "4.5",

          //   months: "3 Months",
          //   chapter: "32 Chapters",
        },
        {
          img: require("./../../assets/images/office.png"),
          title: "Office Automation",
          route:"/officeautopage"
          //   review: "4.5",
          //   fullreview: "(73 Reviews)",

          //   months: "3 Months",
          //   chapter: "32 Chapters",
        },
        {
          img: require("./../../assets/images/tally.png"),
          title: "Tally Prime",
          route:"/tallypage"
          //   review: "4.5",
          //   fullreview: "(73 Reviews)",

          //   months: "3 Months",
          //   chapter: "32 Chapters",
        },
        {
          img: require("./../../assets/images/multimedia.png"),
          title: "Multimedia Course",
          route:"/multymediapage"
          //   review: "4.5",
          //   fullreview: "(73 Reviews)",

          //   months: "3 Months",
          //   chapter: "32 Chapters",
        },
      ],
    };
  },
  computed: {
    // Compute the filtered list based on isExpanded
    // filteredListItems() {
    //   if (this.isExpanded1) {
    //     return this.listItems;
    //   }
    //   else if (this.isExpanded2) {
    //     return this.listItems;
    //   }
    //   else if (this.isExpanded3) {
    //     return this.listItems;
    //   }
    //   else if (this.isExpanded4) {
    //     return this.listItems;
    //   } else {
    //     return this.listItems.slice(0, 5);
    //   }
    // },

    filteredListItems() {
      if (this.isExpanded4) {
        return this.listItems;
      } else {
        return this.listItems.slice(0, 3);
      }
    },
    
  },
  methods: {
    toggleExpansion1() {
      this.isExpanded1 = !this.isExpanded1;
      
    },
    toggleExpansion2() {
      this.isExpanded2 = !this.isExpanded2;
      
    },
    toggleExpansion3() {
      
      this.isExpanded3 = !this.isExpanded3;
    
    },
    toggleExpansion4() {
      
      this.isExpanded4 = !this.isExpanded4;
    },
  },
};
</script>

<style>
.expanded .v-card__text > div {
  display: block; /* Adjust this as needed */
}
</style>y
  